<template>
  <div v-if="!data">Loading Please wait...
  </div>
  <div v-else>
    <validation-observer>
      <b-form>
        <b-row>
          <b-col cols="12" md="12">
            <h4>Info</h4>
            <blockquote>
              Der Fragebogen dient zur Erhebung der grundlegenden Daten für die nachfolgende Besprechung.<br>
              Das korrekte und möglichst vollständige Ausfüllen des Fragebogens beschleunigt den weiteren Verlauf und spart somit Zeit.<br>
              Falls nicht alles klar ist, ist das gar kein Problem, dann werden die Informationen im Rahmen der Besprechung gemeinsam erfasst.
            </blockquote>
          </b-col>

          <b-col cols="12" md="12">
            <app-collapse type="shadow">
              <app-collapse-item title="Allgemein">
                <b-row>
                  <b-col cols="12" md="12">
                    <h4>Info</h4>
                    <blockquote>
                      Die allgemeinen Informationen stellen die relevante Basis für die Abwicklung des Falls dar und steuern die zu erfassenden Inhalte und Beleg.<br>
                  </blockquote>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12" md="12">
                    <b-form-group
                      label="Beschreibung Sie in einigen Sätzen oder Stichworten Ihre Lage - Wohnsituation, Kindern, Betreuung, Finanzen, Herausforderungen, etc."
                      label-for="Situation"
                    >
                      <b-form-textarea
                        v-model="data.Situation"
                        rows="2"
                        max-rows="6"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-show="data.Art !== 2">

                  <b-col cols="12" md="3">
                    <b-form-group
                      label="Ort Zivilstandsamt"
                      label-for="zivilstandsamt"
                    >
                      <b-form-input
                        v-model="data.Zivilstandsamt"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Heirat"
                      rules="required"
                    >
                      <b-form-group
                        label="Heirat"
                        label-for="Heirat"
                      >
                        <flat-pickr
                          v-model="data.Heirat"
                          :config="config"
                          class="form-control"
                        />
                        <small
                          v-show="errors.length"
                          class="text-danger"
                        >{{ $g.browserLocale === 'fr' ? 'Marriage...' : 'Heirat...' }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12" md="3">
                    <validation-provider
                      #default="{ errors }"
                      name="Trennung"
                      :rules="{ required: true, dateGreater: [data.Heirat] }"
                    >
                      <b-form-group
                        label="Trennung"
                        label-for="Trennung"
                      >
                        <flat-pickr
                          v-model="data.Trennung"
                          :config="config"
                          class="form-control"
                        />
                        <small
                          v-show="errors.length"
                          class="text-danger"
                        >{{ $g.browserLocale === 'fr' ? 'Separation...' : 'Trennung muss nach Heirat sein!' }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                </b-row>

                <b-row>
                  <b-col v-show="data.Art !== 2" md="3">
                    <b-form-group
                      label="Güterstand"
                      label-for="gueterstand"
                    >
                      <v-select
                        v-model="data.Gueterstand"
                        :options="gueterstand"
                        :reduce="val => val.value"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col v-show="data.Art !== 2" md="3">
                    <b-form-group
                      label="Gibt es einen Vertrag"
                      label-for="vertrag"
                    >
                      <b-form-checkbox
                        v-model="data.HatVertrag"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="3">
                    <b-form-group
                      :label="$t('anzahl_kinder')"
                      label-for="Kinder (unterstützungspflichtig)"
                    >
                      <vue-slider
                        v-model="data.AnzahlKinder"
                        :min="data.Art === 2 ? 0 : 0"
                        :max="6"
                        :tooltip="'always'"
                        :tooltip-placement="'right'"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group
                      label="Ist die Wohnungs-Situation geregelt?"
                      label-for="wohnungOK"
                    >
                      <b-form-checkbox
                        v-model="data.WohnungOK"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-show="data.Art !== 2">
                  <b-col cols="12" md="3">
                    <b-form-group
                      label="Steuerlich getrennt veranlagt"
                      label-for="sepVeranlast"
                    >
                    <b-form-checkbox
                        v-model="data.SteuerGetrennt"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="3">
                    <b-form-group
                      label="Offene Steuerverbindlichkeiten"
                      label-for="offenSteuer"
                    >
                      <b-form-checkbox
                        v-model="data.SteuerOffen"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                      />
                    </b-form-group>
                  </b-col>

                  <b-col v-show="data.SteuerOffen" cols="12" md="4">
                    <b-form-group
                      label="Begleichung offene Steuerverbindklichkeiten"
                      label-for="aufteilungSteuern"
                    >
                      <v-select
                        v-model="data.SteuerRegel"
                        :options="steuerRegel"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="steuerRegel"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row v-show="data.Art === 1">
                  <b-col v-show="data.AnzahlKinder > 0" cols="12" md="6">
                    <b-form-group
                      :label="'Info: Unterhaltregelung Kind' + (data.AnzahlKinder > 1 ? 'er ' : ' ') + 'während Trennung'"
                      label-for="wochenende"
                    >
                      <b-form-textarea
                        v-model="data.UnterhaltKindTrennung"
                        rows="2"
                        max-rows="3"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group
                      label="Info: Unterhaltsregelung Ehegatte während Trennung"
                      label-for="wochenende"
                    >
                      <b-form-textarea
                        v-model="data.UnterhaltPartnerTrennung"
                        rows="2"
                        max-rows="3"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12" md="4">
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      @click="save_changes"
                    >
                      Speichern
                    </b-button>
                  </b-col>
                  <b-col cols="12" md="4"/>
                </b-row>

              </app-collapse-item>
            </app-collapse>
          </b-col>

          <b-col v-show="!data.WohnungOK" cols="12" md="12">
            <app-collapse type="shadow">
              <app-collapse-item title="Wohnung">
                <b-row>
                  <b-col cols="12" md="12">
                    <h4>Info</h4>
                    <blockquote>
                      Die Regelung der Wohnungssituation kann manchmal etwas schwierig sein und muss entsprechend geregelet werden.
                  </blockquote>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group
                      :label="'Adresse Wohnung bei ' + $root.VNameFrau"
                      label-for="adresseWohnungFrau"
                    >
                      <b-form-checkbox
                        v-model="data.IstAdresseWohnungFrau"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="4">
                    <b-form-group
                      :label="$root.VNameFrau + ' bleibt in der Wohnung'"
                      label-for="frauBleibtInWohnung"
                    >
                      <b-form-checkbox
                        v-model="data.WerWohnungID"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="4">
                    <b-form-group
                      :label="(data.WerWohnungID ? $root.VNameMann : $root.VNameFrau) + ' verlässt die Wohnung bis'"
                      label-for="Heirat"
                    >
                      <flat-pickr
                        v-model="data.WannWohnungVerlassen"
                        :config="config"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>
                  <b-col cols="12" md="4">
                    <b-form-group
                      label="Ist die Wohnung gemietet?"
                      label-for="istMiete"
                    >
                      <b-form-checkbox
                        v-model="data.IstMiete"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="2" v-show="!data.IstMiete">
                    <b-form-group
                      label="Eigentümer"
                      label-for="eigentuemer"
                    >
                      <v-select
                        v-model="data.Eigentuemer"
                        :options="eigentuemer"
                        :reduce="val => val.value"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="4" v-show="data.IstMiete">
                    <b-form-group
                      label="Wurde Mietvertrag bereits übertragen"
                      label-for="mietVertragUebertragen"
                    >
                      <b-form-checkbox
                        v-model="data.IstWohnungUebertragung"
                        class="custom-control-primary"
                        name="check-button"
                        @change="setWohnRegelGericht"
                        switch
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="4" v-show="data.IstMiete && !data.IstWohnungUebertragung">
                    <b-form-group
                      :label="'Antrag Regelung mit Vermieter durch ' + (data.Art === 2 ? 'KESB' : 'Gericht')"
                      label-for="wohnRegelGericht"
                    >
                      <b-form-checkbox
                        v-model="data.WohnRegelGericht"
                        class="custom-control-primary"
                        name="check-button"
                        switch
                      />
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row v-show="data.IstMiete && !data.IstWohnungUebertragung">
                  <b-col cols="12" md="4">
                    <b-form-group
                      label="Datum Mietvertrag"
                      label-for="datummietvertrag"
                    >
                      <flat-pickr
                        v-model="data.MietVertragDatum"
                        :config="config"
                        class="form-control"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="4">
                    <b-form-group
                      label="Vermieter"
                      label-for="vermieter"
                    >
                      <b-form-input
                        v-model="data.Vermieter"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12" md="4">
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      @click="save_changes"
                    >
                      Speichern
                    </b-button>
                  </b-col>
                  <b-col cols="12" md="4"/>
                </b-row>

              </app-collapse-item>
            </app-collapse>
          </b-col>

          <b-col cols="12" md="12">
            <app-collapse type="shadow">
              <app-collapse-item title="Paar">
                <Paar src="fb" />
              </app-collapse-item>
            </app-collapse>
          </b-col>

          <b-col v-show="data.AnzahlKinder > 0" cols="12" md="12">
            <app-collapse type="shadow">
              <app-collapse-item :title="'Kind' + (data.AnzahlKinder > 1 ? 'er' : '')">
                <app-collapse-item title="Betreuung">
                  <Kindbelange src="fb" />
                </app-collapse-item>
                <app-collapse-item title="Grunddaten">
                  <Kind src="fb" />
                </app-collapse-item>
              </app-collapse-item>
            </app-collapse>
          </b-col>

          <b-col v-show="data.MitVA" cols="12" md="12">
            <app-collapse type="shadow">
              <app-collapse-item :title="'Berufliche Vorsorge ' + $root.VNameFrau">
                <Vorsorgeeinrichtung :ist-mutter="1" src="fb" />
              </app-collapse-item>
            </app-collapse>
          </b-col>

          <b-col v-show="data.MitVA" cols="12" md="12">
            <app-collapse type="shadow">
              <app-collapse-item :title="'Berufliche Vorsorge ' + $root.VNameMann">
                <Vorsorgeeinrichtung :ist-mutter="0" src="fb" />
              </app-collapse-item>
            </app-collapse>
          </b-col>

          <b-col cols="12" md="12">
            <app-collapse type="shadow">
              <app-collapse-item title="Unterhalt">
                <app-collapse-item title="Basis">
                  <Unterhalt src="fb" />
                </app-collapse-item>
                <app-collapse-item v-show="data.MitUnterhalt" title="Mittel">
                  <Mittel src="fb" />
                </app-collapse-item>
                <app-collapse-item v-show="data.MitUnterhalt" title="Bedarfe">
                  <Bedarf src="fb" />
                </app-collapse-item>
              </app-collapse-item>
            </app-collapse>
          </b-col>

          <b-col v-show="data.MitGA && data.Art===1" cols="12" md="12">
            <app-collapse type="shadow">
              <app-collapse-item title="Güterrecht">
                <b-row>
                  <b-col cols="12" md="12">
                    <h4>Info</h4>
                    <blockquote>
                      Bitte erfassen Sie alle Vermögenswerte und Schulden, die während der Ehe entstanden sind. Grundsätzlich übernimmt jeder seine persönlichen Gegenstände sowie persönlich eingegangene Schulden.<br>
                      Bei einigen Vermögenswerte ist detailliert festzuhalten, wie diese konkret finanziert worden sind, dazu gehören z. B. Autos und Liegenschaften.<br>
                      Bei Liegenschaften: Liegenschaftsart, Eigentümer, Kaufwert, aktueller Wert, WEF-Vorbezug (inkl. Rückzahlungen), Informationen Hypothek, Renovationen oder Um-/Ausbauten, etc.
                  </blockquote>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group
                      label="Gemeinsame Vermögenswerte und Schulden"
                      label-for="vf"
                    >
                      <b-form-textarea
                        v-model="data.GueterGemein"
                        rows="8"
                        max-rows="10"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group
                      :label="'Vermögenswerte und Schulden ' + $root.VNameFrau"
                      label-for="vf"
                    >
                      <b-form-textarea
                        v-model="data.GueterFrau"
                        rows="8"
                        max-rows="10"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="4">
                    <b-form-group
                      :label="'Vermögenswerte und Schulden ' + $root.VNameMann"
                      label-for="vf"
                    >
                      <b-form-textarea
                        v-model="data.GueterMann"
                        rows="8"
                        max-rows="10"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col cols="12" md="4">
                    <b-button
                      variant="primary"
                      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      @click="save_changes"
                    >
                      Speichern
                    </b-button>
                  </b-col>
                  <b-col cols="12" md="4"/>
                </b-row>

              </app-collapse-item>
            </app-collapse>
          </b-col>

        </b-row>

      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
// import { required } from '@validations'
import Vue from 'vue'
import VueMask from 'v-mask'

import {
  BButton, BRow, BCol, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import VueSlider from 'vue-slider-component'
import Ripple from 'vue-ripple-directive'
import { German } from 'flatpickr/dist/l10n/de'
import { French } from 'flatpickr/dist/l10n/fr'
import fb from '@/service/fragebogen'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import Paar from '../grunddaten/Paar.vue'
import Kind from '../grunddaten/Kind.vue'
import Unterhalt from '../grunddaten/Unterhalt.vue'
import Kindbelange from '../grunddaten/Kindbelange.vue'
import Vorsorgeeinrichtung from '../vorsorge/Vorsorgeeinrichtung.vue'
import Mittel from '../mittel/Mittel.vue'
import Bedarf from '../bedarf/Bedarf.vue'

Vue.use(VueMask)

export default {
  components: {
    BButton,
    BRow,
    BCol,
    VueSlider,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    flatPickr,
    AppCollapse,
    AppCollapseItem,
    Paar,
    Kind,
    Kindbelange,
    Unterhalt,
    Vorsorgeeinrichtung,
    Mittel,
    Bedarf,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: null,
      // required,
      eigentuemer: [],
      erziehungsgutschriftGeteilt: true,
      erziehungsgutschriftMutter: false,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        dateFormat: 'Y-m-d', // Internes Format zu Speicherung...
        altInput: true,
        altFormat: 'd.m.Y', // Anzeigeformat...
        allowInput: true,
        locale: this.$g.user.Sprache === 'fr' ? French : German,
      },
    }
  },
  async mounted() {
    this.$root.$refs.FRAGEBOGEN = this

    extend('dateGreater', {
      async validate(value, p) {
        if (p[0] <= value) return true
        return 'Datum kleiner als vorheriges Datum!'
      },
    })

    this.fetchData()
  },

  async created() {
    this.eigentuemer = [
      { label: 'Gemeinsam', value: 0 },
      { label: this.$root.VNameMann, value: 1 },
      { label: this.$root.VNameFrau, value: 2 },
    ]

    this.steuerRegel = [
      { label: this.$root.VNameMann + ' begleicht offene Steuern', value: 'mann' },
      { label: this.$root.VNameFrau + ' begleicht offene Steuern', value: 'frau' },
      { label: 'hälftige Teilung ' + this.$root.VNameMann + ' und ' + this.$root.VNameFrau, value: 'halb' },
      { label: '%-uale Teilung Verhältnis Gehälter', value: 'proz' },
    ]

    this.gueterstand = [
      { label: 'Errungenschaft', value: 'errungenschaft' },
      { label: 'Gütertrennung', value: 'trennung' },
      { label: 'Gütergemeinschaft', value: 'gemeinschaft' },
    ]
  },
  setup() {
    const sprachen = [
      { label: 'Deutsch', value: 'de' },
      { label: 'Français', value: 'fr' },
    ]

    return {
      sprachen,
    }
  },
  methods: {
    async fetchData() {
      const r = await fb.getFragebogen(this.$root.currDosID)
      if (r !== null && r.data.length === 1) this.data = r.data[0]
    },
    isFormDirty() {
      return Object.keys(this.fields).some(key => this.fields[key].dirty)
    },
    async save_changes() {
      await fb.putFragebogen(this.data)
    },
    async setWohnRegelGericht() {
      this.data.WohnRegelGericht = !this.data.IstWohnungUebertragung
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
